*{
    font-family: 'Montserrat', sans-serif;
}
.parent .heading2 {
    margin-left: 180px;
    margin-top: 15px;

}

.parent .App-container {
    width: 900px;
    display: flex;
    margin: auto;
    border: solid black 0px;
    margin-top: 10px;
    padding-top: 20px;
    padding: 10px;

}

.parent .app-heading {
    font-size: 20px;
    font-family: sans-serif;
    letter-spacing: 5px;
}

.parent .App-container-one {
    width: 100px;
    min-height:100%;
    max-height: 100%;
    background-color:#fff;
    overflow: hidden;
}

.parent .App-container-two {
    width: 3000px;
    min-height: 100vh;
    background-color: #fff;
}


.parent .App-container-two h1 {
    display: flex;
    justify-content: center;
}

.parent .App-box-right h4 {
    font-size: 14px;
    font-weight: 300;
    margin-top: 5px;
    font-weight: 500;
}

.parent .App-box-right {
    margin-top: 20px;
    margin-left: 5px;
}

.parent .App-container-two-big {
    display: flex;
    flex-direction: row;
}

.parent .App-container-two-right {
    margin-left: 50px;
    margin-top: 60px;
}

.parent .App-container-two-right h3 {
    margin-top: 0px;
    font-weight: 300;
    font-size: 12px;
    padding: 0px;
    font-weight:500;
    margin-bottom: -10px;
}

.parent .App-container-two-left {
    display: flex;
    flex-direction: column;
}

.parent .table {
    display: flex;
    margin-left: -10px;
    margin-top: 30px;


}

.parent table,
.parent th,
.parent td {
    border-collapse: collapse;
}

.parent td,
.parent th {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 25px;
    padding-right: 10px;
    border-bottom: 1px solid black;
    font-family: sans-serif;


}

.parent .last-container {
    display: flex;
    flex-direction: row;
    margin-top: 30px;
    width: 93.8%;
}

.parent .last-container-left {
    font-size: 13px;
    margin-left: 5px;
    display: flex;
    justify-content: left;
    align-items: left;
    font-family: sans-serif;
}

.parent .last-container-right {
    margin-left: 44px;
    font-size: 12px;
}

.parent .App-last-container {
    margin-left: 5px;
    margin-top: 90px;
}
.parent .last-container {
    display: flex;
    flex-direction: row;
    margin-top: 5px !important;
    margin-right: 21px;
    float: right;
    justify-content: flex-end;
}
.parent .App-last-container h6 {
    margin-top: 15px;
    font-family: sans-serif;
    font-weight: 200;
    font-size: 12px;
}

.parent .App-last-container h5 {
    margin-top: 5px;
}

.parent .green {
    width: 800px;
    background-color: #fff;
    height: 60px;
    margin-top: 200px;
    border: solid black 1px!important;
}

.table th {
    color: black!important;
}

/* .parent td, .parent th {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 25px;
    padding-right: 10px;
    border-bottom: 1px solid black;
    font-family: sans-serif;
    min-width: 149px;
} */

h6#border {
    border: solid black 1px;
    padding: 10px;
    margin-bottom: -11px;
    width: 100%;
    margin-top: -1px;

}

.bg-body-tertiary {
    --bs-bg-opacity: 1;
    background-color: white!important;
    padding: 10px;
    padding-left: 40px;
    padding-right: 50px;
    width: 106%;
    margin-left: -42px;
    margin-top: -7px;
}
.table>:not(caption)>*>* {
    border-bottom-width: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
    background-color: #fff;
}
h6#border {
    border: solid black 1px;
    padding: 10px;
    margin-bottom: -11px;
    width: 97%;
    margin-top: -1px;
}
.parent .last-container-left {
    font-size: 13px;
    margin-left: 5px;
    display: flex;
    justify-content: center;
    align-items: left;
    font-family: sans-serif;
    float: left;
    margin-left: -32px;
    width: 77%;
}

.parent .Table-container-six table tbody tr td{
    width: 110%;
}
.parent .App-container-two-right {
    margin-left: 230px;
    margin-top: 60px;
    width: 50%;
}
h6#proposalwords {
    text-align: left;
    width: 100%;
}

.right-pro {
    width: 50%;
    overflow: hidden;
}

 .parent .App-container-two-right {
    margin-top: 60px;
    width: 50%;
    float: right;
    margin-left: 0px!important;
}